export const AUTH_RESULT = "AUTH_RESULT";
export const AUTH_SET_PROFILE = "AUTH_SET_PROFILE";
export const AUTH_RENEWTOKEN_RESULT = "AUTH_RENEWTOKEN_RESULT";

export const IDP_START_RENEW_TOKEN = "IDP_START_RENEW_TOKEN";
export const IDP_HANDLE_RENEW_TOKEN = "IDP_HANDLE_RENEW_TOKEN";
export const IDP_START_LOGOUT = "IDP_START_LOGOUT";
export const IDP_START_LOGIN = "IDP_START_LOGIN";
export const IDP_RESULT = "IDP_RESULT";
export const IDP_HANDLE_AUTHENTICATION = "IDP_HANDLE_AUTHENTICATION";

export const SAGA_CHANGE_LANGUAGE = "SAGA_CHANGE_LANGUAGE";
export const APP_SET_LANGUAGE = "APP_SET_LANGUAGE";
export const APP_SET_SCREEN_THEME = "APP_SET_SCREEN_THEME";
export const APP_SET_SCREEN_POSITION = "APP_SET_SCREEN_POSITION";
export const LAYOUT_SET_SIDEBAR = "LAYOUT_SET_SIDEBAR";

export const SET_USER_INFO = "SET_USER_INFO";
export const SET_USER_PROFILE = "SET_USER_PROFILE";
export const SET_USER_MENU = "SET_USER_MENU";

export const ORGANIZATION_SERVICE_PLANS = "ORGANIZATION_SERVICE_PLANS";
export const SET_ORGANIZATION_SERVICE_PLANS = "SET_ORGANIZATION_SERVICE_PLANS";

export const APP_ACTIVITIES = "APP_ACTIVITIES";

export const APP_LOOKUP_RELIGION = "APP_LOOKUP_RELIGION";
export const APP_LOOKUP_ISSUERTYPE = "APP_LOOKUP_ISSUERTYPE";
export const APP_LOOKUP_ROLELIST = "APP_LOOKUP_ROLELIST";
export const APP_LOOKUP_ADDROLE = "APP_LOOKUP_ADDROLE";
export const APP_LOOKUP_ENUM_GENDER = "APP_LOOKUP_ENUM_GENDER";
export const APP_LOOKUP_ENUM_BLOOD_TYPE = "APP_LOOKUP_ENUM_BLOOD_TYPE";
export const APP_LOOKUP_ENUM_ALLERGY_TYPE = "APP_LOOKUP_ENUM_ALLERGY_TYPE";
export const APP_LOOKUP_ENUM_ALLERGY_SEVERITY =
  "APP_LOOKUP_ENUM_ALLERGY_SEVERITY";
export const APP_LOOKUP_ENUM_MARITAL_STATUS = "APP_LOOKUP_ENUM_MARITAL_STATUS";
export const APP_LOOKUP_ENUM_INTERVALS = "APP_LOOKUP_ENUM_INTERVALS";
export const APP_LOOKUP_SPEAKING_LANGUAGES = "APP_LOOKUP_SPEAKING_LANGUAGES";
export const APP_LOOKUP_OCCUPATIONS = "APP_LOOKUP_OCCUPATIONS";
export const APP_LOOKUP_DOCUMENT_CATEGORY = "APP_LOOKUP_DOCUMENT_CATEGORY";

export const APP_LOOKUP_DRUG_DOSE_UNIT = "APP_LOOKUP_DRUG_DOSE_UNIT";
export const APP_LOOKUP_DRUG_FORM = "APP_LOOKUP_DRUG_FORM";
export const APP_LOOKUP_DRUG_TYPE = "APP_LOOKUP_DRUG_TYPE";
export const APP_LOOKUP_HUMAN_BODY = "APP_LOOKUP_HUMAN_BODY";
export const APP_LOOKUP_SYMPTOMS = "APP_LOOKUP_SYMPTOMS";
export const APP_LOOKUP_GQUALIFIER = "APP_LOOKUP_GQUALIFIER";
export const APP_LOOKUP_SQUALIFIER = "APP_LOOKUP_SQUALIFIER";

export const APP_LOOKUP_QUESTIONS = "APP_LOOKUP_QUESTIONS";
export const APP_LOOKUP_INFORMATIONS = "APP_LOOKUP_INFORMATIONS";
export const APP_LOOKUP_ANSWERS = "APP_LOOKUP_ANSWERS";
export const APP_LOOKUP_HINTS = "APP_LOOKUP_HINTS";

export const SET_WEBRTC_ISCONNECTED = "SET_WEBRTC_ISCONNECTED";
export const SET_WEBRTC_GET_CLIENTS = "SET_WEBRTC_GET_CLIENTS";
export const SET_WEBRTC_GET_CLIENT = "SET_WEBRTC_GET_CLIENT";
export const SET_WEBRTC_REMOVE_CLIENT = "SET_WEBRTC_REMOVE_CLIENT";
export const SET_WEBRTC_DATA = "SET_WEBRTC_DATA";

export const SET_INTERNAL_PROVIDER_DASHBOARD_MODULES_MENU =
  "SET_INTERNAL_PROVIDER_DASHBOARD_MENU_MODULES";
export const SET_INTERNAL_PROVIDER_DASHBOARD_TOOLBOX =
  "SET_INTERNAL_PROVIDER_DASHBOARD_TOOLBOX";
export const SET_INTERNAL_PROVIDER_DASHBOARD_TOOLBOX_ISOPEN =
  "SET_INTERNAL_PROVIDER_DASHBOARD_TOOLBOX_ISOPEN";

export const SET_INTERNAL_PROVIDER_DASHBOARD_DRAGITEM =
  "SET_INTERNAL_PROVIDER_DASHBOARD_DRAGITEM";
export const SET_INTERNAL_PROVIDER_DASHBOARD_GRIDLAYOUT =
  "SET_INTERNAL_PROVIDER_DASHBOARD_GRIDLAYOUT";

export const SET_INTERNAL_PROVIDER_DASHBOARD_PROFILES =
  "SET_INTERNAL_PROVIDER_DASHBOARD_PROFILES";

export const SET_INTERNAL_PROVIDER_DASHBOARD_SELECTED_PROFILE =
  "SET_INTERNAL_PROVIDER_DASHBOARD_SELECTED_PROFILE";
export const SET_INTERNAL_PROVIDER_DASHBOARD_GLOBAL_FILTER =
  "SET_INTERNAL_PROVIDER_DASHBOARD_GLOBAL_FILTER";

export const SET_INTERNAL_PROVIDER_DASHBOARD_UPDATE_MODULE =
  "SET_INTERNAL_PROVIDER_DASHBOARD_UPDATE_MODULE";

export const SET_INTERNAL_PROVIDER_DASHBOARD_IS_DIALOG_OPEN =
  "SET_INTERNAL_PROVIDER_DASHBOARD_IS_DIALOG_OPEN";

export const SET_INTERRAL_PROVIDER_SELECTED_SUBSCIBER_SERVICE_PLANS =
  "SET_INTERRAL_PROVIDER_SELECTED_SUBSCIBER_SERVICE_PLANS";

export const SET_INTERNAL_PROVIDER_DASHBOARD_RESIZE_EVENT =
  "SET_INTERNAL_PROVIDER_DASHBOARD_RESIZE_EVENT";
