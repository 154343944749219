import { isArray, isObject } from "lodash";
import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function userReducer(
  state = initialState.internalProviderDashboard,
  action
) {
  switch (action.type) {
    case types.SET_INTERNAL_PROVIDER_DASHBOARD_MODULES_MENU:
      return {
        ...state,
        menuModules: action.menuModules,
      };
    case types.SET_INTERNAL_PROVIDER_DASHBOARD_TOOLBOX:
      return {
        ...state,
        toolbox: action.toolbox,
      };
    case types.SET_INTERNAL_PROVIDER_DASHBOARD_TOOLBOX_ISOPEN:
      if (action.index >= 0 && action.index < state.toolbox.length) {
        const newToolbox = state.toolbox.map((item, index) => {
          if (index === action.index) {
            return {
              ...item,
              isOpen: !item.isOpen,
            };
          }
          return { ...item, isOpen: false };
        });
        return {
          ...state,
          toolbox: newToolbox,
        };
      } else return state;
    case types.SET_INTERNAL_PROVIDER_DASHBOARD_DRAGITEM:
      return {
        ...state,
        dragItem: action.dragItem,
      };
    case types.SET_INTERNAL_PROVIDER_DASHBOARD_GRIDLAYOUT:
      return {
        ...state,
        gridLayout: action.gridLayout,
      };
    case types.SET_INTERNAL_PROVIDER_DASHBOARD_PROFILES:
      return {
        ...state,
        profiles: action.profiles,
        selectedProfileId: action.selectedProfileId,
      };
    case types.SET_INTERNAL_PROVIDER_DASHBOARD_SELECTED_PROFILE:
      return {
        ...state,
        selectedProfile: action.selectedProfile,
      };
    case types.SET_INTERNAL_PROVIDER_DASHBOARD_UPDATE_MODULE:
      const module = action.module;
      // if (isArray(module) && module.length <= 0 && action.action !== "clear")
      //   return state;
      const newModules = [...state.updatedModules];
      const moduleIndex = newModules.findIndex((prev) => prev.i === module.i);
      if (action.action === "add") {
        return {
          ...state,
          updatedModules: module,
        };
      } else if (action.action === "remove") {
        if (moduleIndex > -1) {
          newModules.splice(moduleIndex, 1);
        }
      } else if (action.action === "clear") {
        return {
          ...state,
          updatedModules: [],
        };
      } else if (action.action === "replace") {
        return {
          ...state,
          updatedModules: module,
        };
      } else {
        if (moduleIndex > -1) {
          newModules[moduleIndex] = module;
        } else {
          if (!isArray(module)) newModules.push(module);
        }
      }
      return {
        ...state,
        updatedModules: newModules,
      };
    case types.SET_INTERNAL_PROVIDER_DASHBOARD_GLOBAL_FILTER:
      return {
        ...state,
        globalFilter: action.globalFilter,
      };
    case types.SET_INTERNAL_PROVIDER_DASHBOARD_IS_DIALOG_OPEN:
      const newUpdateModules = state.updatedModules.map((m) => {
        return {
          ...m,
          static: action.isDialogOpen,
          isDraggable: !action.isDialogOpen,
          isResizable: !action.isDialogOpen,
        };
      });
      return {
        ...state,
        updatedModules: newUpdateModules,
        isDialogOpen: action.isDialogOpen,
      };
    case types.SET_INTERRAL_PROVIDER_SELECTED_SUBSCIBER_SERVICE_PLANS:
      return {
        ...state,
        selectedSubscriberServicePlans: action.selectedSubscriberServicePlans,
      };
    case types.SET_INTERNAL_PROVIDER_DASHBOARD_RESIZE_EVENT:
      return {
        ...state,
        resizedItem: action.resizedItem,
      };
    default:
      return state;
  }
}
